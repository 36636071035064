
import { defineComponent, computed, ref } from 'vue';

import { useRouter } from 'vue-router';

export default defineComponent({
  components: {},
  setup() {
    const router = useRouter();
    const items = ref(1);
    return {
      items,
    };
  },
});
