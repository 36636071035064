<style lang="scss">
#frontend {
  .marken-box-outer-service-partner {
    .grid-container {
      justify-content: center;
      position: relative;
      transition: all 200ms ease;
      width: 100%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px;
      grid-template-rows: 100px 100px 100px 100px 100px 100px 100px 100px;
      grid-gap: 10px;
      grid-template-areas:
        '. . box17 box17 box17 . . box16 box16 box16 . .'
        '. . . . box11 box11 . . box22 box22 . .'
        'box5 box5 box5 . box11 box11 box7 box7.  box6 box6 box6'
        '. . box18 box18  . . . . box4 box4 . .'
        '. . . . . . box2 box2 . box21 box21 .'
        'box20 box20 box20 . box10 box10 . . . box21 box21 .'
        '. . box13 box13 box13 . . . box12 box12 . .'
        '. box3 box3 . box14 box14 box14 . . box15 box15 .';

      @media (max-width: 1300px) {
        grid-template-columns: 80px 80px 80px 80px 80px 80px 80px 80px 80px 80px 80px 80px;
        grid-template-rows: 80px 80px 80px 80px 80px 80px 80px 80px;
      }

      @media (max-width: 1200px) {
        grid-template-columns: 90px 90px 90px 90px 90px 90px 90px 90px 90px;
        grid-template-rows: 90px 90px 90px 90px 90px 90px 90px 90px;
        grid-template-areas:
          '. box7 box7 box7 . box22 box22 box2 box2  '
          '. . . box4 box4  box5 box5 box5 . '
          '. box10 box10 . box13 box13 box13 box3 box3 '
          '. . . box6 box6 box6 . box21 box21 '
          '. box12 box12 . box17 box17 box17 box21 box21 '
          '. . . box18 box18 . . box11 box11 '
          '. box15 box15 . box16 box16 box16 box11 box11 '
          '. . box14 box14 box14 . box20 box20 box20 ';
      }

      @media (max-width: $width-lg) {
        grid-template-columns: 100px 100px 100px 100px 100px 100px;
        grid-template-rows: 100px 100px 100px 100px 100px 100px 100px 100px;
        grid-template-areas:
          'box11 box11 . box17 box17 box17'
          'box11 box11 . box18 box18 .'
          'box12 box12 box15 box15 box6 box6'
          'box13 box13 box13 . box2 box2'
          'box10 box10 box3 box3 box16 box16'
          'box5 box5 box20 box20 box4 box4'
          'box21 box21 . . box7 box7'
          'box21 box21 box14 box14 box22 box22';
      }

      @media (max-width: $width-md) {
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
        justify-content: center;
        grid-gap: 0;
        grid-template-areas:
          'box10 box2'
          'box3 box4'
          'box5 box6'
          'box7 box20'
          'box11 box12'
          'box13 box14'
          'box15 box16'
          'box17 box18'
          'box21 box22';
      }
    }

    .outer-box {
      @media (max-width: $width-sm) {
        width: 100%;
        margin: 0 auto;
      }

      @media (max-width: 400px) {
        width: 100%;
      }

      .inner-box {
        position: relative;
        width: 100%;
        height: 100%;

        //background:#ededed;
        @media (max-width: $width-md) {
          width: 100%;
          height: 230px;
          margin: 0 auto;
          border: 1px solid #fff;
          //border:2px solid red !important;
        }

        @media (max-width: $width-sm) {
          width: 100%;
          height: 180px;
          margin: 0 auto;
          border: 1px solid #fff;
        }

        a {
          //border:1px solid red;
          display: block;
          position: relative;
          height: 100%;
          transition: $transition-std;

          img {
            padding: 0 10px;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            margin: 0 auto;

            @media (max-width: $width-md) {
              width: 70%;
              display: block;
              margin: 0 auto;
            }
          }

          .img-smaller {
            @media (max-width: $width-md) {
              width: 50%;
            }
          }

          .img-smaller-v2 {
            @media (max-width: $width-lg) {
              width: 90%;
            }

            @media (max-width: $width-md) {
              width: 35%;
            }

            @media (max-width: $width-sm) {
              width: 40%;
            }
          }

          .img-kiz-klz,
          .img-rca {
            @media (max-width: $width-xl) {
              padding: 0 30px;
            }

            @media (max-width: $width-md) {
              padding: 0px;
            }
          }

          &:hover {
            transform: scale(0.9);
          }
        }

        .padding-30 {
          padding: 0 30px;

          @media (max-width: $width-lg) {
            padding: 0 15px;
          }

          @media (max-width: $width-md) {
            padding: 0px;
          }
        }

        .padding-15 {
          padding: 0 15px;

          @media (max-width: $width-md) {
            padding: 0px;
          }
        }
      }

      $alpha-wert: 0.3;

      .bg-blue-60per {
        background-color: rgba($color: $blue-60per, $alpha: $alpha-wert);
      }

      .bg-mint {
        background-color: rgba($color: $mint, $alpha: $alpha-wert);
      }

      .bg-violet-50per {
        background-color: rgba($color: $violet-50per, $alpha: $alpha-wert);
      }

      .bg-sand {
        background-color: rgba($color: $sand, $alpha: $alpha-wert);
      }

      .bg-grey {
        background-color: rgba($color: $grey, $alpha: $alpha-wert);
      }

      .bg-wine-red {
        background-color: rgba($color: $wine-red, $alpha: $alpha-wert);
      }

      .bg-yellow {
        background-color: rgba($color: $yellow, $alpha: $alpha-wert);
      }

      .bg-sand {
        background-color: rgba($color: $sand, $alpha: $alpha-wert);
      }

      .bg-joom-boos {
        background-color: rgba($color: #f7ae11, $alpha: $alpha-wert);
      }

      .bg-klz {
        background-color: rgba($color: #c50d0f, $alpha: $alpha-wert);
      }

      .bg-antenne {
        background-color: rgba($color: #ffed00, $alpha: $alpha-wert);
      }

      .bg-wienerin {
        background-color: rgba($color: #c71565, $alpha: $alpha-wert);
      }

      .bg-presse {
        background-color: rgba($color: #1a233b, $alpha: $alpha-wert);
      }

      .bg-furche {
        background-color: rgba($color: #931813, $alpha: $alpha-wert);
      }

      .bg-willhaben {
        background-color: rgba($color: #00a9e9, $alpha: $alpha-wert);
      }

      .bg-ligaportal {
        background-color: rgba($color: #005ea7, $alpha: $alpha-wert);
      }

      .bg-24sata {
        background-color: rgba($color: #cd1719, $alpha: $alpha-wert);
      }

      .bg-vercernji {
        background-color: rgba($color: #e63f39, $alpha: $alpha-wert);
      }

      .bg-ballguide {
        background-color: rgba($color: #cead72, $alpha: $alpha-wert);
      }

      .bg-bolha {
        background-color: rgba($color: #f0b501, $alpha: $alpha-wert);
      }

      .bg-grazer {
        background-color: rgba($color: #f0b501, $alpha: $alpha-wert);
      }

      .bg-connect724 {
        background-color: rgba($color: #d70a27, $alpha: $alpha-wert);
      }

      .border-coral {
        border: 5px solid rgba($color: $coral, $alpha: $alpha-wert);

        @media (max-width: $width-md) {
          border: 0;
        }
      }

      .border-blue {
        border: 5px solid rgba($color: $blue, $alpha: $alpha-wert);

        @media (max-width: $width-md) {
          border: 0;
        }
      }

      .border-blue-60per {
        border: 5px solid rgba($color: $blue-60per, $alpha: $alpha-wert);

        @media (max-width: $width-md) {
          border: 0;
        }
      }

      .border-mint {
        border: 5px solid rgba($color: $mint, $alpha: $alpha-wert);

        @media (max-width: $width-md) {
          border: 0;
        }
      }

      .border-violet-50per {
        border: 5px solid rgba($color: $violet-50per, $alpha: $alpha-wert);

        @media (max-width: $width-md) {
          border: 0;
        }
      }

      .border-sand {
        border: 5px solid rgba($color: $sand, $alpha: $alpha-wert);

        @media (max-width: $width-md) {
          border: 0;
        }
      }

      .border-grey {
        border: 5px solid rgba($color: #ccc, $alpha: $alpha-wert);

        @media (max-width: $width-md) {
          border: 0;
        }
      }

      .border-wine-red {
        border: 5px solid rgba($color: $wine-red, $alpha: $alpha-wert);

        @media (max-width: $width-md) {
          border: 0;
        }
      }

      .border-yellow {
        border: 5px solid rgba($color: $yellow, $alpha: $alpha-wert);

        @media (max-width: $width-md) {
          border: 0;
        }
      }

      .border-sand {
        border: 5px solid rgba($color: $sand, $alpha: $alpha-wert);

        @media (max-width: $width-md) {
          border: 0;
        }
      }
    }

    .box1 {
      grid-area: box1;
    }

    .box2 {
      grid-area: box2;
    }

    .box3 {
      grid-area: box3;
    }

    .box4 {
      grid-area: box4;
    }

    .box5 {
      grid-area: box5;
    }

    .box6 {
      grid-area: box6;
    }

    .box7 {
      grid-area: box7;
    }

    .box8 {
      grid-area: box8;
    }

    .box9 {
      grid-area: box9;
    }

    .box10 {
      grid-area: box10;
    }

    .box11 {
      grid-area: box11;
    }

    .box12 {
      grid-area: box12;
    }

    .box13 {
      grid-area: box13;
    }

    .box14 {
      grid-area: box14;
    }

    .box15 {
      grid-area: box15;
    }

    .box16 {
      grid-area: box16;
    }

    .box17 {
      grid-area: box17;
    }

    .box18 {
      grid-area: box18;
    }

    .box19 {
      grid-area: box19;
    }

    .box20 {
      grid-area: box20;
    }

    .box21 {
      grid-area: box21;
    }

    .box22 {
      grid-area: box22;
    }

    .container-marken {
      max-width: 1920px;
      margin-left: auto;
      margin-right: auto;
    }

    .slider-outer-marken {
      //border:1px solid red;
      padding-top: 80px;
      padding-bottom: 80px;
      position: relative;

      .arrow-outer {
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;

        .arrow-all {
          position: absolute;
          height: 50px;
          width: 50px;

          cursor: pointer;

          img {
            display: block;
            max-width: 100%;
          }

          &:hover {
            opacity: 0.7;
          }
        }

        .arrow-left {
          left: 20%;

          @media (max-width: $width-md) {
            left: 10%;
          }
        }

        .arrow-right {
          right: 30.5%;
          bottom: 0;

          @media (max-width: $width-lg) {
            right: 50%;
            margin-right: -25px;
          }
        }

        .arrow-right-v2 {
          right: 30.5%;
          top: 0;
          display: none;

          @media (max-width: $width-lg) {
            display: block;
            right: 50%;
            margin-right: -25px;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="marken-box-outer-service-partner">
    <div class="container-marken">
      <div class="slider-outer-marken">
        <div class="row no-gutters ">
          <div class="col-12">
            <div class="grid-container">
              <div class="outer-box box2">
                <div class="inner-box border-wine-red" style="" v-inview="['animation-delay-0-5s', 'scale-in']">
                  <router-link :to="{ name: 'call-and-mail' }">
                    <img src="@/assets/img/marken/brands/styria-call-and-mail.svg" alt="Call & Mail" class="img-fluid " />
                  </router-link>
                </div>
              </div>
              <div class="outer-box box3">
                <div class="inner-box border-blue-60per" v-inview="['animation-delay-1-5s', 'scale-in']">
                  <router-link :to="{ name: 'car4you' }">
                    <img src="@/assets/img/marken/brands/car4you.svg" alt="Car4you" class="img-fluid" />
                  </router-link>
                </div>
              </div>
              <div class="outer-box box4">
                <div class="inner-box border-violet-50per" v-inview="['animation-delay-1-5s', 'scale-in']">
                  <router-link :to="{ name: 'connect724' }">
                    <img src="@/assets/img/marken/brands/connect724.svg" alt="Connect 724" class="img-fluid padding-30" />
                  </router-link>
                </div>
              </div>
              <div class="outer-box box5">
                <div class="inner-box border-mint" v-inview="['animation-delay-0-5s', 'scale-in']">
                  <router-link :to="{ name: 'druck-styria' }">
                    <img src="@/assets/img/marken/brands/styria-druck-styria.svg" alt="Druck Styria" class="img-fluid padding-30" />
                  </router-link>
                </div>
              </div>
              <div class="outer-box box6">
                <div class="inner-box border-sand" v-inview="['animation-delay-0-5s', 'scale-in']">
                  <router-link :to="{ name: 'druck-carinthia' }">
                    <img src="@/assets/img/marken/brands/styria-druck-carinthia.svg" alt="Druck Carinthia" class="img-fluid padding-30" />
                  </router-link>
                </div>
              </div>
              <div class="outer-box box7">
                <div class="inner-box border-sand" v-inview="['animation-delay-0-5s', 'scale-in']">
                  <router-link :to="{ name: 'styria-fides' }">
                    <img src="@/assets/img/marken/brands/styria-fides.svg" alt="Styria Fides" class="img-fluid" />
                  </router-link>
                </div>
              </div>

              <div class="outer-box box10">
                <div class="inner-box border-sand" v-inview="['animation-delay-0-5s', 'scale-in']">
                  <router-link :to="{ name: 'pixsell' }">
                    <img src="@/assets/img/marken/brands/pixsell.svg" alt="Pixsell" class="img-fluid" />
                  </router-link>
                </div>
              </div>
              <div class="outer-box box11">
                <div class="inner-box border-mint" v-inview="['animation-delay-1s', 'scale-in']">
                  <router-link :to="{ name: 'radio-content-austria' }">
                    <img src="@/assets/img/marken/brands/rca.svg" alt="RCA" class="img-fluid img-rca" />
                  </router-link>
                </div>
              </div>
              <div class="outer-box box12">
                <div class="inner-box border-blue-60per" v-inview="['animation-delay-0-5s', 'scale-in']">
                  <router-link :to="{ name: 'redmail' }">
                    <img src="@/assets/img/marken/brands/redmail.svg" alt="Redmail" class="img-fluid padding-30" />
                  </router-link>
                </div>
              </div>
              <div class="outer-box box13">
                <div class="inner-box border-mint" v-inview="['animation-delay-1s', 'scale-in']">
                  <router-link :to="{ name: 'styria-digital-development' }">
                    <img
                      src="@/assets/img/marken/brands/styria-digital-development.svg"
                      alt="Styria Digital Development"
                      class="img-fluid padding-30"
                    />
                  </router-link>
                </div>
              </div>
              <div class="outer-box box14">
                <div class="inner-box border-violet-50per" v-inview="['animation-delay-1s', 'scale-in']">
                  <router-link :to="{ name: 'styria-digital-services' }">
                    <img src="@/assets/img/marken/brands/styria-digital-services.svg" alt="Styria Digital Services" class="img-fluid padding-15" />
                  </router-link>
                </div>
              </div>
              <div class="outer-box box15">
                <div class="inner-box border-mint" v-inview="['animation-delay-0-5s', 'scale-in']">
                  <router-link :to="{ name: 'styria-it-solutions' }">
                    <img src="@/assets/img/marken/brands/styria-it.svg" alt="Styria IT Solutions" class="img-fluid" />
                  </router-link>
                </div>
              </div>
              <div class="outer-box box16">
                <div class="inner-box border-mint" v-inview="['animation-delay-0-5s', 'scale-in']">
                  <router-link :to="{ name: 'styria-marketing-services' }">
                    <img
                      src="@/assets/img/marken/brands/styria-marketing-services.svg"
                      alt="Styria Marketing Services"
                      class="img-fluid padding-15"
                    />
                  </router-link>
                </div>
              </div>
              <div class="outer-box box17">
                <div class="inner-box border-wine-red" v-inview="['animation-delay-1s', 'scale-in']">
                  <router-link :to="{ name: 'styria-media-design' }">
                    <img src="@/assets/img/marken/brands/styria-media-design.svg" alt="Styria Media Design" class="img-fluid padding-30" />
                  </router-link>
                </div>
              </div>
              <div class="outer-box box18">
                <div class="inner-box border-grey" v-inview="['animation-delay-1s', 'scale-in']">
                  <router-link :to="{ name: 'njuskalo-technology' }">
                    <img src="@/assets/img/marken/brands/njuskalo-technology.png" alt="Njuskalo Technology" class="img-fluid " />
                  </router-link>
                </div>
              </div>
              <div class="outer-box box20">
                <div class="inner-box border-blue-60per" v-inview="['animation-delay-1-5s', 'scale-in']">
                  <router-link :to="{ name: 'tiskara-zagreb' }">
                    <img src="@/assets/img/marken/brands/styria-tiskara-zagreb.svg" alt="Tiskara Zagreb" class="img-fluid padding-30" />
                  </router-link>
                </div>
              </div>
              <!-- <div class="outer-box box21">
                <div class="inner-box border-yellow" v-inview="['animation-delay-0-5s', 'scale-in']">
                  <router-link :to="{ name: 'mm-produktion-und-services' }">
                    <img src="@/assets/img/marken/brands/mm-produktion-services.svg" alt="Mm Produktion und Services"
                      class="img-fluid padding-30 img-smaller-v2" />
                  </router-link>
                </div>
              </div> -->
              <div class="outer-box box22">
                <div class="inner-box border-coral" v-inview="['animation-delay-0-5s', 'scale-in']">
                  <router-link :to="{ name: 'ballguide' }">
                    <img src="@/assets/img/marken/brands/ballguide.svg" alt="ballguide" class="img-fluid img-smaller" />
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';

import { useRouter } from 'vue-router';

export default defineComponent({
  components: {},
  setup() {
    const router = useRouter();
    const items = ref(1);
    return {
      items,
    };
  },
});
</script>
